body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* max-width: 100vw; */
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home, .product {
  width: 100%;
  height: 100vh;
}

.background_video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* max-width: 100vw; */
}

.home .content {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.home .overlay {
  position: absolute;
  top: rem(56px);
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6
  );
}

.product {
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 32px 0;
  gap: 10px;
}

.horizontal_stack {
  display: flex;
  flex-direction: row;
}

.featuresContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto; 
}

.productCard {
  width: 100%;
  max-width: 400px; 
  margin: 0 auto; 
}

/* 
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 1; 
  position: sticky;
  top: 1rem;
} */

.product-image-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto; 
  align-items: center;
}