.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;  
  /* padding-top: calc(var(--mantine-spacing-xl) * 4); */
  padding-bottom: calc(var(--mantine-spacing-xl));
  gap: var(--mantine-spacing-xl);
  overflow: hidden;
  flex-direction: row; /* Default to row for wider screens */

  @media (max-width: 768px) {
      flex-direction: column; /* Change to column for smaller screens */
  }
}
  
  .content {
    max-width: rem(480px);
    /* margin-right: calc(var(--mantine-spacing-xl) * 3); */
  
    @media (max-width: 992px) {
      max-width: 100%;
      margin-right: 0;
    }
  }
  
  .title {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: rem(44px);
    line-height: 1.2;
    font-weight: 900;
    text-align: center;

  
    @media (max-width: 576px) {
      font-size: rem(28px);
    }
  }
  
  .control {
    @media (max-width: 576px) {
      flex: 1;
    }
  }
  
  .image {
    width: rem(376px);
    height: rem(356px);
  
    @media (max-width: 992px) {
      display: none;
    }
  }
  
  .highlight {
    position: relative;
    background-color: var(--mantine-color-blue-light);
    border-radius: var(768px);
    padding: rem(4px) rem(12px);
  }
