.header {
  height: rem(56px);
  background-color: var(--mantine-color-body);
  position: sticky;
  width: 100%;
  z-index: 1000;
  top: 0px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
}

.hide {
  top: -100px;
}

.inner {
  height: rem(56px);
  min-height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: block;
  line-height: 1;
  /* padding: rem(8px) rem(12px); */
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }

  [data-mantine-color-scheme] &[data-active] {
    background-color: var(--mantine-color-blue-filled);
    color: var(--mantine-color-white);
  }
}
