.linkedin_icon {
    position: relative;
    top: 5px;
  }

.email_icon {
    position: relative;
    top: 2px;
  }

.title {
    position: relative;
    bottom: 115px;
}

.horizontal_stack {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-around;
  flex-direction: row;
}