.footer {
    margin-top: rem(120px);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
    /* background-color: yellow; */
    width: 100%;
  }
  
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0  64px;
    gap: var(--mantine-spacing-lg);
    @media (max-width: 576px    ) {
      flex-direction: column;
      padding: 0 16px;
    }
    width: 100%;
    /* background-color: red; */
  }
  
  .links {
    @media (max-width: 576px) {
      margin-top: 768px;
    }
  }

.footerButton:hover {
  text-decoration: underline;
}
